// Generated by Framer (552ec80)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-FLs80"

const variantClassNames = {xzgoLSMrC: "framer-v-1cvlvl6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xzgoLSMrC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1cvlvl6", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"xzgoLSMrC"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FLs80.framer-1hy4yow, .framer-FLs80 .framer-1hy4yow { display: block; }", ".framer-FLs80.framer-1cvlvl6 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; max-width: 1440px; min-height: 487px; overflow: visible; padding: 0px 80px 0px 80px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FLs80.framer-1cvlvl6 { gap: 0px; } .framer-FLs80.framer-1cvlvl6 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-FLs80.framer-1cvlvl6 > :first-child { margin-top: 0px; } .framer-FLs80.framer-1cvlvl6 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 486.5
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"],"constraints":[null,"1440px",null,null]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGlAAJpygn: React.ComponentType<Props> = withCSS(Component, css, "framer-FLs80") as typeof Component;
export default FramerGlAAJpygn;

FramerGlAAJpygn.displayName = "Case Study / Information";

FramerGlAAJpygn.defaultProps = {height: 486.5, width: 1200};

addFonts(FramerGlAAJpygn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})